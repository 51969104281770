export const complaint = {
  complaint: "",
  duration: ""
}

export const presenting_complaint = {
  complaints: [  ], //contains complaint objects
  course: "",
  cause: "",
  complications: "",
  care_so_far: "",
  notes: ""
}